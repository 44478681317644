$(document).ready(function(){ 
    var timer;

    $(".switch-stripe__sub-trigger").mouseenter(function() {
        if($(window).width() > 660) {
            var that = $(this).closest('.switch-stripe__sub');
            stageFadeIn(that)
        }
       

    })

    $('.switch-stripe__sub-trigger').mouseleave(function() {
        if($(window).width() > 660) {
        var that = $(this).closest('.switch-stripe__sub');
        stageFadeOut(that) 
        }
    })



    function stageFadeIn(stagePart) {
        $(stagePart).addClass('switch-stripe__sub--active');

        clearTimeout(timer);
    
    }
    
    
    function stageFadeOut(stagePart) {
        $(stagePart).removeClass('switch-stripe__sub--active');


    
    }
    
    


})



