$(document).ready(function () {
    $('.quote-slider__slider').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1600,
        pauseOnHover: false,
        arrows: true,
        dots: true,
        appendDots: $('.quote-slider__dots'),
        prevArrow: $('.quote-slider__arrow-left'),
        nextArrow: $('.quote-slider__arrow-right'),
    
      });
    
    })