$(document).ready(function(){ 
    
    function filter(category) {
        $('.team__item').hide()
        $('.team__tab').removeClass('team__tab--active')
        $(`.team__item--${category}`).fadeIn()
        $(`.team__tab[data-teamfilter="${category}"]`).addClass('team__tab--active')
    }


    filter('geschaeftsfuehrer')

    $('.team__tab').click(function() {
        filter($(this).data('teamfilter'))
    })
})



