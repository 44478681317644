$(document).ready(function () {

    //Close NAVi
    function closeNavi() {

        $('.main-navigation__nav-link--active').removeClass('main-navigation__nav-link--active');
        $('.main-navigation__nav-point--active').removeClass('main-navigation__nav-point--active');
        if (checkWidth() < 900) {
            $('.hamburger').removeClass("is-active")
            $('.main-navigation').removeClass("main-navigation--active");

            $('body').removeClass('lock-body')
        }
        $('.main-navigation-overlay').hide()


        $('.navbar-mobile__container').removeClass("navbar-mobile__container--active");
        $('.navbar-mobile__hamburger').removeClass('is-active');
        $('.navbar-mobile__icon--hamburger').removeClass("navbar-mobile__icon--hamburger-active")
        $('.overlay').hide();
    }
    //ENDE CLOSE NAVI

    // Check if Header is active //
    function isHeaderActive() {
        $('.main-header').hasClass('main-header--active')
    }


    //Close Mobile navi
    function closeMobileNavi() {
        if (checkWidth() < 900) {
            closeNavi()
            console.log("close nav")
        }
    }



    // Mobile navi
    $('.hamburger').click(() => {
        $('.hamburger').toggleClass("is-active")
        $('.main-navigation').toggleClass("main-navigation--active");
        $('.main-header').toggleClass('main-header--active')

        hideSearchBar();
        hideLanguageBar();

        $('.main-navigation-overlay').toggle()
        $('body').toggleClass('lock-body')

    });



    //Show Subnavi
    $('.main-navigation__nav-link--has-subnavi').click(function (a) {
        a.preventDefault();
        hideSearchBar()
        $('.language-bar').hide();
        if ($(this).hasClass('main-navigation__nav-link--active')) {
            if (checkWidth() > 960) {
                $('.main-header').removeClass('main-header--active')

                $('.main-navigation-overlay').hide()

            }
            $(this).removeClass('main-navigation__nav-link--active')
            $(this).closest('.main-navigation__nav-point').removeClass('main-navigation__nav-point--active')

        } else {
            if (checkWidth() > 960) {
                $('.main-header').addClass('main-header--active')
                $('.main-navigation__nav-link--active').removeClass('main-navigation__nav-link--active');
                $('.main-navigation-overlay').show()
                $('.main-navigation__nav-point--active').removeClass('main-navigation__nav-point--active');

            }

            $(this).addClass('main-navigation__nav-link--active')
            $(this).closest('.main-navigation__nav-point').addClass('main-navigation__nav-point--active')

        }
    })

    //////// WENN AUF OOVERLAY CLICK //////
    $('.main-navigation-overlay').click(() => {
        $('.main-header').removeClass('main-header--active')
        $('.main-navigation__nav-link--active').removeClass('main-navigation__nav-link--active')
        $('.main-navigation__nav-point--active').closest('.main-navigation__nav-point').removeClass('main-navigation__nav-point--active')
        $('.main-navigation-overlay').hide()
    })


    function hideSearchBar() {
        $('.searchbar').hide();
        $('.main-header__search-button').removeClass('main-header__search-button--active')
    }

    function hideLanguageBar() {
        $('.language-bar').hide();
        $('.main-header__language-button').removeClass('main-header__language-button--active')
    }


    // Check Size of Window
    function checkWidth() {
        var windowsize = $(window).width();
        return windowsize
    }



    /////////////////////////////////START  Search Bar /////////////////////////////////


    // Show searchbar
    $('.searchbar').hide();

    $('.main-header__search-button').click(() => {
        closeNavi()
        //Falls Suchbar  aktiv ist
        if ($('.main-header__search-button').hasClass('main-header__search-button--active')) {
            $('.searchbar').slideUp('fast');
            $('.main-header').removeClass('main-header--active')
            $('.main-header__search-button').removeClass('main-header__search-button--active')

        } else {
            hideLanguageBar()
            $('.main-header').addClass('main-header--active')
            $('.searchbar').slideDown('fast');
            $('.main-header__search-button').addClass('main-header__search-button--active')
        }

    })


    //Close
    $('.searchbar__close').click(() => {
        $('.searchbar').slideUp(('fast'));
        $('.main-header__search-button').removeClass('main-header__search-button--active')
        $('.main-header').removeClass('main-header--active')

    });


    /////////////////////////////////ENDE  Search Bar /////////////////////////////////


    /////////////////////////////////START  Language Bar /////////////////////////////////


    // Show searchbar
    $('.language-bar').hide();

    $('.main-header__language-button').click(() => {
        closeNavi()
        //Falls Suchbar  aktiv ist
        if ($('.main-header__language-button').hasClass('main-header__language-button--active')) {
            $('.language-bar').slideUp('fast');
            $('.main-header').removeClass('main-header--active')
            $('.main-header__language-button').removeClass('main-header__language-button--active')

        } else {
            hideSearchBar()
            $('.main-header').addClass('main-header--active')
            $('.language-bar').slideDown('fast');
            $('.main-header__language-button').addClass('main-header__language-button--active')
        }

    })


    //Close
    $('.language-bar__close').click(() => {
        $('.language-bar').slideUp(('fast'));
        $('.main-header__language-button').removeClass('main-header__language-button--active')
        $('.main-header').removeClass('main-header--active')

    });


    /////////////////////////////////ENDE  Language Bar /////////////////////////////////



})