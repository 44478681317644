
$( document ).ready(function() {

    // --------------  GALLERY Slider ---------- //


    // Start Loop
   /*  $('.gallery-slider__container').each(function (key, item) {

        var sliderIdName = 'gallery-slider' + key;
        var sliderPrevName = 'gallery-slider__arrow-left' + key;
        var sliderNextName = 'gallery-slider__arrow-right' + key;


        this.id = sliderIdName;

        $('.gallery-slider__arrow-left')[key].id = sliderPrevName;
        $('.gallery-slider__arrow-right')[key].id = sliderNextName;

        var sliderId = '#' + sliderIdName;
        var sliderPrevId = '#' + sliderPrevName;
        var sliderNextId = '#' + sliderNextName;

        $(sliderId).slick({
            autoplay: true,
            autoplaySpeed: 6000,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            centerMode: true,
            variableWidth: true,
            arrows: true,
            prevArrow: $(sliderPrevId),
            nextArrow: $(sliderNextId),
            dots: false,
        });




    }); */
    //Ende loop

    $('.gallery-slider__container').slick({
        autoplay: true,
        autoplaySpeed: 6000,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
        arrows: true,
        prevArrow: $('.gallery-slider__arrow-left'),
        nextArrow: $('.gallery-slider__arrow-right'),
        dots: false,
        asNavFor: '.gallery-claim-slider'
    });

    $('.gallery-claim-slider').slick({
        autoplaySpeed: 6000,
        speed: 300,
        slidesToShow: 1,
        centerMode: false,
        arrows: false,
        
        dots: false,
    });

    $(window).resize(function () {
        $('.gallery-slider__container').slick('resize')
    
    });



})
