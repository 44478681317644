  //////////////////READ MORE ////////////////////

  $(document).ready(function () {
      $('.chronik__row--hide').hide()
  $('#chronik-more').click(function() {
    if($(this).hasClass('chronik__show-more--active')){
        $(this).closest('.chronik').find('.chronik__row--hide').hide()
        $(this).text($(this).data('showmore'))
        $(this).removeClass('chronik__show-more--active')
    }

    else {
        $(this).closest('.chronik').find('.chronik__row--hide').show()
        $(this).text($(this).data('showless'))
        $(this).addClass('chronik__show-more--active')

    }
    
})

  })