$(document).ready(function () {
$('.image-slider__slider').slick({
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1600,
    
    pauseOnHover: false,
    arrows: true,
    dots: true,
    appendDots: $('.image-slider__dots'),
    prevArrow: $('.image-slider__arrow-left'),
    nextArrow: $('.image-slider__arrow-right'),

  });

})