$(document).ready(function () {
	try {
		lightbox.option({
			albumLabel: "",
			maxWidth: 1200,

			positionFromTop: 120,
			alwaysShowNavOnTouchDevices: true,
			fadeDuration: 100,
			// 'resizeDuration': 00,
			fitImagesInViewport: true,
		});

		$(".set-lightbox").each(function (index) {
			var lightboxID = "lightbox-group" + index;

			$(this)
				.find("img")
				.each(function (index) {
					var imageURL = $(this).attr("src");
					$(this).wrap(`<a href="${imageURL}" data-lightbox="${lightboxID}">`);
				});
		});
	} catch (error) {}
});
