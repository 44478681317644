document.addEventListener("DOMContentLoaded", () => {
	const contents = document.querySelectorAll(".floating-button--viewport");
	const triggers = document.querySelectorAll(".floating-button__stage-content");

	if (contents.length > 0 && triggers.length > 0) {
		let content = contents[0];
		let trigger = triggers[0];

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					content.classList.remove("visible");
				} else {
					content.classList.add("visible");
				}
			});
		});

		observer.observe(trigger);
	} else {
		console.error("One or both elements not found");
	}
});
