window.klaroConfig = {
  translations: {
    zz: {
      privacyPolicyUrl: "/datenschutz",
    },
    de: {
      googleAnalytics: {
        title: "Google Analytics",
        description: "Analyse der Websitenutzung.",
      },
      purposes: {
        analytics: "Analyse",
      },
      consentNotice: {
        description:
          "Wir verwenden Cookies, um Ihren Besuch so komfortabel wie möglich zu gestalten. " +
          "Unsere Seite nutzt dazu Website Tracking-Cookies von Dritten, zu einer anonymisierten Statistikerstellung. " +
          "Einzelheiten zu den eingesetzten Technologien, Hinweise zu deren Widerrufsmöglichkeiten sowie Informationen zur Datenweitergabe an Dritte entnehmen Sie bitte unseren " +
          '<a href="/datenschutz">Datenschutzbestimmungen</a>.',
      },
      consentModal: {
        description:
          "Wir verwenden Cookies, um Ihren Besuch so komfortabel wie möglich zu gestalten. " +
          "Unsere Seite nutzt dazu Website Tracking-Cookies von Dritten, zu einer anonymisierten Statistikerstellung. ",
      },
    },
  },
  htmlTexts: true,
  services: [
    {
      name: "googleAnalytics",
      purposes: ["analytics"],
      default: true,
    },
  ],
};
