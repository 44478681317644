// AKKORDEON  //
try {
  $(document).ready(function () {
    var allPanels = $(".akkordeon__content-item");
    allPanels.not(".akkordeon__content-item--active").hide();
    $(".akkordeon__head-item").click(function () {
      if (!$(this).hasClass("akkordeon__head-item--active")) {
        allPanels.slideUp();
        $(".akkordeon__head-item").removeClass("akkordeon__head-item--active");
        $(".akkordeon__content-item").removeClass(
          "akkordeon__content-item--active"
        );
        $(this).toggleClass("akkordeon__head-item--active");
        $(this).next().toggleClass("akkordeon__content-item--active");
        $(this).next().slideDown();
      } else {
        $(this).removeClass("akkordeon__head-item--active");
        $(this).next().removeClass("akkordeon__content-item--active");
        $(this).next().slideUp();
      }
    });
  });
} catch (error) {
  console.error(error);
}
