
// -----> Settings
const GOOGLE_ANALYTICS_ID = "UA-27914148-1"; // Google-Analytics ID
const COOKIE_EXPIRATION_DAYS = 7 // Tage, nach denen der Cookie abläuft

// -----> Prüfen. ob Cookies im verwendeten Browser erlaubt sind
var cookieEnabled = navigator.cookieEnabled;

$(document).ready(function () {

    // onload Funktionen
    if (cookieEnabled) {
        window.dataLayer = window.dataLayer || [];
        getLayerState();
        getCheckboxState();
    } else {
        disableCookieSettings();
    }

    // --------------------> Events <--------------------

    // -----> Accept Cookies
    $(".cookie-layer__button-yes").click(function () {
        jQuery(".cookie-layer").hide("fast");
        jQuery(".cookie-layer__overlay").removeClass('cookie-layer__overlay--active');
        setCookie("cookieOptin", "in", COOKIE_EXPIRATION_DAYS);
       
        // Push to GTM's dataLayer after user consent
        pushConsentEvent('accepted');

        loadGoogleTagsOnConsent();
        loadElfsight();
    });


    // -----> Reject Cookies
    $(".cookie-layer__button-no").click(function () {
        jQuery(".cookie-layer").hide("slow");
        setCookie("cookieOptin", "out", COOKIE_EXPIRATION_DAYS);

        // Push to GTM's dataLayer after user rejection 
        pushConsentEvent('rejected');
    });


    // -----> Change Cookie-Settings
    $('#change-cookies').find('input').change(function () {
        if ($(this).prop("checked") == true) {
            setCookie("cookieOptin", "in", COOKIE_EXPIRATION_DAYS);
        } else {
            setCookie("cookieOptin", "out", COOKIE_EXPIRATION_DAYS)
        }
    });
});

// Function to push events to dataLayer only after it's initialized
function pushConsentEvent(consentStatus) {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'cookieConsentGiven',
            consentStatus: consentStatus
        });
    } else {
        console.warn("dataLayer is not defined yet, retrying...");
        setTimeout(function() {
            pushConsentEvent(consentStatus); // Retry if dataLayer isn't available
        }, 500); // Retry after 500ms
    }
}

/**
 * -----> setCookie
 *
 * setzt einen Cookie.
 *
 * @param {string} cname Cookie name
 * @param {string} cvalue Cookie value
 * @param {int} exdays Days till expiration
 */
function setCookie (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires + ";path=/; ";
    getCheckboxState();
}


/**
 * -----> getCookie
 *
 * Auslesen von bereits gesetzten Cookies.
 *
 * @param {string} cname Cookie-name
 */
function getCookie (cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
}


/**
 * -----> loadGoogleTagsOnConsent
 *
 * Lädt Google Tag Manager nach Zustimmung
 */
function loadGoogleTagsOnConsent() {
    if(window.dataLayer == null) {
        window.dataLayer = window.dataLayer || [];
    }

    // Load Google Tag Manager dynamically only after consent
    var gtmScript = document.createElement("script");
    gtmScript.async = true;
    gtmScript.src = "https://www.googletagmanager.com/gtm.js?id=GTM-KBSK6JZ7";
    document.getElementsByTagName("head")[0].appendChild(gtmScript);

    // Add noscript for GTM in body
    var gtmNoscript = document.createElement("noscript");
    gtmNoscript.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KBSK6JZ7" height="0" width="0" style="display:none;visibility:hidden"></iframe>';
    document.body.appendChild(gtmNoscript);
}

function loadElfsight() {
    console.log("load Elfsight ...");
	let esscript = document.createElement("script");
	// esscript.async = true;
	esscript.src = "https://static.elfsight.com/platform/platform.js";
	esscript.setAttribute("data-use-service-core", true);
	esscript.setAttribute("defer", true);

    document.getElementsByTagName("head")[0].appendChild(esscript, document.getElementsByTagName("head")[0]);

    document.getElementById("ratings").classList.add("elfsight-app-3979b7f9-fbe9-4435-9482-ac0797ffaf4b");
    console.log("Elfsight loaded");

    // let rating = document.createElement("div");
    // rating.classList.add("elfsight-app-3979b7f9-fbe9-4435-9482-ac0797ffaf4b");
    // document.getElementById.
}


/**
 * -----> getLayerState
 *
 * Prüft, ob ein cookie gesetzt ist und zeigt/versteckt den Cookie-Layer
 */
function getLayerState() {
    if ((getCookie("cookieOptin") == "in") || (getCookie("cookieOptin") == "out")) {
        $(".cookie-layer").hide();
        $(".cookie-layer__overlay").removeClass('cookie-layer__overlay--active');

        pushConsentEvent(getCookie("cookieOptin") === "in" ? "accepted" : "rejected");

    } else {
        $(".cookie-layer").show();
        $(".cookie-layer__overlay").addClass('cookie-layer__overlay--active');
    };
}


/**
 * -----> getCheckboxState
 *
 * setzt die Checkbox der Cookie-Einstellungen auf den aktuellen Wert
 */
function getCheckboxState() {
    if (getCookie("cookieOptin") == "in") {
        loadGoogleTagsOnConsent();
        loadElfsight();
        $('#change-cookies').find('input').prop('checked', true)
    } else {
        $('#change-cookies').find('input').prop('checked', false)
    };
}


function disableCookieSettings() {
    $('#change-cookies').find('input').attr('disabled', true);
}
