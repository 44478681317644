$(document).ready(function () {

   /////////////////// FUNKTIONEN ///////////////////
    // Check Size of Window
    function checkWidth() {
        var windowsize = $(window).width();
        return windowsize
    }
    /////////////////// //////////////////



    ///////// Slider Stage ///////
    //Check ob Unterschrift Mit Link vorhanden ist
    var stageCTA;
    if ($('.stage-slider-cta').length) {
        stageCTA = '.stage-slider-cta';
    } else {
        stageCTA = false
    };

    $('.slider').slick({
        autoplay: true,
        autoplaySpeed: 6000,

        fade: true,
        speed: 1600,
        pauseOnHover: false,
        arrows: true,
        prevArrow: $('.stage-main-part__arrow-left'),
        nextArrow: $('.stage-main-part__arrow-right'),
        asNavFor: stageCTA

    });



    $('.stage-slider-cta').slick({

        fade: true,
        speed: 1600,

        arrows: false,

    });


    // START Image-Text-Teaser Slider

 

  


            $('.icon-text-teaser-slider').slick({
                autoplay: false,
                autoplaySpeed: 6000,
                variableWidth: false,
                slidesToShow: 3,
                fade: false,
                speed: 800,
                arrows: true,
                dots: true,
                appendDots: $('.icon-text-teaser-slider__nav'),
                prevArrow: $('.icon-text-teaser-slider__nav-prev'),
                nextArrow: $('.icon-text-teaser-slider__nav-next'),
                infinite: false,
                responsive: [
                    {
                      breakpoint: 960,
                      settings: {
                        variableWidth: true,
                        slidesToShow: 1,
                      }
                    }
                  ]

            })


       
 
    // ENDE Image Text Teaser Slider

    

    //VIDEO Lightbox
    $('.lightbox--trigger').click(function () {
        var lightboxname = $(this).data('lightboxname');
        $('#' + lightboxname).fadeIn()
        $('#' + lightboxname).find('iframe').attr({
            src: $('#' + lightboxname).find('.video-lightbox__video').data('youtubeembedurl')
        });
    })

    $('.video-lightbox__close').click(function () {
        $(this).closest('.video-lightbox__overlay').fadeOut()
        $(this).closest('.video-lightbox__overlay').find('iframe').removeAttr('src')
    })
    $('.video-lightbox__overlay').click(function (e) {
        if (e.target == this) {
            $('.video-lightbox__overlay').fadeOut();
            $(this).closest('.video-lightbox__overlay').find('iframe').removeAttr('src')
        }

    })


    /////////////SHRINK NAVI /////////////
    //Shrink Menu
    var lastScrollTop = 0;
    let root = document.documentElement;
    $(window).scroll(function (event) {
        var st = $(this).scrollTop();


        if (st > 100) {
            $(".main-header").addClass("main-header--scrolled");
            if (checkWidth() > 660) {
                root.style.setProperty('--main-header-height', "10rem");
            }
        } else {
            $(".main-header").removeClass("main-header--scrolled");
            if (checkWidth() > 660) {
                root.style.setProperty('--main-header-height', "12rem");

            }
        }
        lastScrollTop = st;
    });


})