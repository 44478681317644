document.addEventListener("DOMContentLoaded", function () {
  function setDescriptionWidth() {
    const descriptions = document.querySelectorAll(
      ".vcard-text__item-description"
    );
    let maxWidth = 0;

    descriptions.forEach((desc) => {
      const width = desc.offsetWidth;
      if (width > maxWidth) {
        maxWidth = width;
      }
    });

    const container = document.querySelector(".vcard-text__list");
    if (container) {
      container.style.setProperty(
        "--vcard-description-min-width",
        `${maxWidth}px`
      );
    }
  }

  setDescriptionWidth();

  let resizeTimer;
  window.addEventListener("resize", function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(setDescriptionWidth, 100);
  });
});
